import * as React from 'react';
import styled from '../../styled-components';
import { joinPath } from '../../utils';
import { AllProductProps } from '../types';
import ProductListItem from './ProductListItem';

const ProductListRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: ${props => props.theme.baseMargin * -1}rem;
`;

const ProductList = ({ allContentfulProduct }: AllProductProps) => (
  <ProductListRow>
    {allContentfulProduct
      ? allContentfulProduct.edges.map(
          ({
            node: { title: productTitle, slug, productFamily, productImage }
          }) => {
            // Build the product path
            const path = joinPath(
              productFamily[0].parentSection.slug,
              productFamily[0].slug,
              slug
            );
            return (
              <ProductListItem key={path} to={path}>
                {productImage ? (
                  <img src={productImage.resolutions.src} />
                ) : null}
                <h5>{productTitle}</h5>
              </ProductListItem>
            );
          }
        )
      : null}
  </ProductListRow>
);

export default ProductList;
