import { graphql, Link } from 'gatsby';
import styled from '../../styled-components';

/** Product list item fragment */
export const productListItemFragment = graphql`
  fragment ProductListItemFragment on ContentfulProduct {
    id
    slug
    title
    productFamily {
      slug
      parentSection {
        slug
      }
    }
    productImage {
      resolutions(width: 300, quality: 70) {
        src
      }
    }
  }
`;

const ProductListItem = styled(Link)`
  width: 175px;
  margin-left: ${props => props.theme.baseMargin}rem;
  margin-bottom: ${props => props.theme.baseMargin}rem;
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 0.3rem;
  overflow: hidden;
  border: 0;

  &:hover {
    text-decoration: none;
  }

  img {
    max-height: 120px;
    object-fit: cover;
    min-width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h5 {
    display: block;
    padding: ${props => props.theme.baseMargin / 2}rem;
    min-height: 48px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.9em;
    color: white;
  }
`;

export default ProductListItem;
