import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import ProductFamily from '../components/ProductFamily';
import {
  AllProductFamilyProps,
  AllProductProps,
  AllSectionProps,
  ProductFamilyProps
} from '../components/types';

export const productFamilyQuery = graphql`
  query ProductFamilyQuery($id: String!) {
    ...AllSectionFragment
    ...AllProductFamilyFragment
    contentfulProductFamily(id: { eq: $id }) {
      ...ProductFamilyFragment
    }
    allContentfulProduct(
      filter: { productFamily: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          ...ProductListItemFragment
          ...ProductFragment
        }
      }
    }
  }
`;

const getBreadcrumbPath = (props: ProductFamilyProps & AllSectionProps) => {
  const home = props.allContentfulSection.edges[0].node;
  const parent = props.contentfulProductFamily.parentSection;
  const active = {
    slug: props.contentfulProductFamily.slug,
    title: props.contentfulProductFamily.title
  };

  return [home, parent, active].map(({ slug, title }) => ({
    slug,
    title
  }));
};

const ProductFamilyTemplate = ({
  data,
  location
}: {
  data: ProductFamilyProps &
    AllProductFamilyProps &
    AllSectionProps &
    AllProductProps;
  pageContext: any;
  location: { pathname: string };
}) => (
  <Layout
    title={
      data.contentfulProductFamily.pageHeaderTitle ||
      data.contentfulProductFamily.title
    }
    breadcrumbPath={getBreadcrumbPath(data)}
    currentSectionPath={data.contentfulProductFamily.parentSection.slug}
    currentLocation={location.pathname}
    menuData={data}
  >
    <ProductFamily
      allContentfulProductFamily={data.allContentfulProductFamily}
      contentfulProductFamily={data.contentfulProductFamily}
      allContentfulProduct={data.allContentfulProduct}
    />
  </Layout>
);

export default ProductFamilyTemplate;
