import { graphql } from 'gatsby';
import * as React from 'react';
import AlertList from '../AlertList';
import CallToAction, { CallToActionContainer } from '../CallToAction';
import { Column, Container } from '../Grid';
import MarkdownComponent from '../MarkdownComponent';
import PageHelmet from '../PageHelmet';
import ProductFamilyNav from '../ProductFamilyNav';
import { SectionRow } from '../Section';
import SectionTitle from '../SectionTitle';

import ProductContent from '../Product/ProductContent';
import {
  AllProductFamilyProps,
  AllProductProps,
  ProductFamilyProps
} from '../types';
import ProductList from './ProductList';

export const productFamilyFragment = graphql`
  fragment ProductFamilyFragment on ContentfulProductFamily {
    id
    title
    pageHeaderTitle
    h1Title
    slug
    metaKeywords {
      metaKeywords
    }
    metaDescription {
      metaDescription
    }
    metaRobots
    copy {
      childMarkdownRemark {
        html
      }
    }
    parentSection {
      ...SectionFragment
    }
    collapseToFirstProduct
  }
`;

const getBackgroundImage = (productFamily: any) => {
  const [pageTitle] = productFamily.parentSection?.pageTitle || [];
  return (pageTitle as any)?.backgroundImage;
};

const ProductFamily = ({
  contentfulProductFamily: {
    id,
    metaKeywords,
    metaDescription,
    metaRobots,
    ...contentfulProductFamily
  },
  allContentfulProductFamily,
  allContentfulProduct
}: ProductFamilyProps & AllProductFamilyProps & AllProductProps) => {
  const {
    title,
    h1Title,
    copy,
    collapseToFirstProduct
  } = contentfulProductFamily;
  const backgroundImage = getBackgroundImage(contentfulProductFamily);

  return (
    <>
      <PageHelmet
        key="helmet"
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
        metaRobots={metaRobots}
        id={id}
      />
      <SectionTitle
        key="title"
        headline={h1Title || title}
        backgroundImage={backgroundImage}
      />
      <Container>
        <AlertList />
        <SectionRow key="row">
          <Column lg={3}>
            <ProductFamilyNav
              allContentfulProductFamily={allContentfulProductFamily}
              contentfulProductFamily={contentfulProductFamily}
              allContentfulProduct={allContentfulProduct}
            />
          </Column>
          <Column lg={9}>
            {collapseToFirstProduct && allContentfulProduct?.edges?.length ? (
              <ProductContent
                contentfulProduct={allContentfulProduct.edges[0].node as any}
              />
            ) : (
              <>
                {allContentfulProduct && allContentfulProduct.edges.length ? (
                  <ProductList allContentfulProduct={allContentfulProduct} />
                ) : null}
                <MarkdownComponent {...copy} />
                <CallToActionContainer>
                  <CallToAction
                    navigateToSlug="/contact-us"
                    buttonText="Contact us today"
                    primaryOrSecondary="primary"
                  />
                </CallToActionContainer>
              </>
            )}
          </Column>
        </SectionRow>
      </Container>
    </>
  );
};

export default ProductFamily;
